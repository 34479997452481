html {
  width: 100vw;
  height: 100vh;
  body {
    margin: 0;
    flex: 1;
    height: 100vh;
    width: 100vw;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    #root {
      flex: 1;
      display: flex;
      max-height: 100vh;
      max-width: 100vw;
      width: 100vw;
      height: 100vh;
      flex-direction: column;
    }
  }
}
.alertbox{
  visibility: hidden;
}
